@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins.woff) format('woff');
}

@font-face {
  font-family: 'Effra';
  src: local('Effra'), url('./fonts/Effra.woff') format('woff');
}

body {
  margin: 0;
  font-family: Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  /* Disabling cursor outside input box*/
  /*-webkit-user-select: none;  !* Chrome all / Safari all *!*/
  /*-moz-user-select: none;     !* Firefox all *!*/
  /*-ms-user-select: none;      !* IE 10+ *!*/
  /*user-select: none;*/
}

code {
  font-family: Poppins;
}

h1 {
  font-family: Effra !important;
}

h2 {
  font-family: Effra !important; 
}

h3 {
  font-family: Effra !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

h4 {
  font-family: Effra !important;
}

h5 {
  font-family: Effra !important;
}

.pi-star-fill:before {
  content: "\e936";
  color: yellow !important;
}

/*Changing original colors from primereact to DataTable */

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #2B678F;
  color: white;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #2B678F;
  color: white;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #2B678F;
  color: white;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #ffffff;
}