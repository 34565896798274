.containerExterno{
    background-color: #00205c;
}

h3{
    color:#ec407a;
}

.containerInterno {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 85vw;
    margin: 0 auto;
}

.text{
    width: 70vw;
    font-size: 1.3em;
    color: white;
    margin-right: 50px;
}

.img{
    max-width: 140px;
}


@media (min-width: 650px){
    .containerExterno{
        display: none;
    }
}

@media (max-width: 500px){
    .containerInterno {
        font-size: 0.5rem;
        text-align: center;
        flex-direction: column;
    }

    .text{
        font-size: 2.5em;
        margin-right: 0px;
        margin-bottom: 30px;
    }
}

@media (max-width: 324px){
    .text{
        font-size: 2.1em;
    }
}
