.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

.p-fileupload .p-fileupload-buttonbar {
    padding: 0.8em !important;
}

.p-component {
    font-family: Poppins !important;
}